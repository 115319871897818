<template>
  <div class="navbar">
    <div class="breadcrumb">
      <span>{{$route.meta.title}}</span>
    </div>
    <div class="right_bar">
      <div class="item">
        <img src="../../assets/images/msg.png"/>
        <sup class="nav_badge">3</sup>
      </div>
      <div class="item">
        <img class="avatar" :src="$store.state.user.headImg"/>
        <span class="username">{{$store.state.user.nickName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped lang="scss">
  .navbar{
    height: 54px;
    width: 100%;
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 17px 6px rgb(226 228 234 / 28%);
    .breadcrumb{
      float: left;
      line-height: 54px;
      span{
        border-left: 3px solid #F59034;
        padding-left: 8px;
        font-size: 16px;
        color: #414A60;
        margin-left: 20px;
      }
    }
    .right_bar{
      padding-right: 30px;
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      .item{
        display: flex;
        position: relative;
        align-items: center;
        margin-left: 30px;
      }
      .nav_badge{
        width: 18px;
        line-height: 18px;
        background: #FB8F1C;
        border-radius: 9px;
        position: absolute;
        color: #ffffff;
        text-align: center;
        right: -6px;
        top: -8px;
      }
      img{
        width: 24px;
        height: 24px;
      }
      .avatar{
        border-radius: 50%;
      }
      .username{
        font-size: 14px;
        text-indent: 6px;
      }
    }
  }
</style>
