<template>
  <div class="app-wrapper">
    <sidebar></sidebar>
    <div class="main-container">
      <navbar></navbar>
      <div class="app-main">
          <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  import Sidebar from './Sidebar/index'
  import navbar from './Navbar/index'

  export default {
    name: 'index',
    components: {
      Sidebar,
      navbar
    }
  }
</script>

<style scoped lang="scss">
  .app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .main-container {
    margin-left: 155px;
    position: relative;
    min-height: 100%;
    height: 100%;
  }

  .app-main {
    min-width: 1266px;
    padding: 20px 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #eef0f5;
    min-height: calc(100vh - 54px);
    /*min-height: calc(100% - 54px);*/
  }
</style>
