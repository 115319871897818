import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const outerKey = 'writingTool-Token'

export function getToken(TokenKey) {
  return Cookies.get(TokenKey)
}

export function setToken(TokenKey,token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken(TokenKey) {
  return Cookies.remove(TokenKey)
}


export function getOuterToken() {
  return Cookies.get(outerKey)
}

export function setOuterToken(token) {
  return Cookies.set(outerKey, token)
}
