import request from '@/utils/request'

export function queryMyBookingList(query) {
  return request({
    url: '/visitor/queryMyBookingList',
    method: 'get',
    params: query
  })
}
export function queryConsultantInfo(query) {
  return request({
    url: '/visitor/queryConsultantInfo',
    method: 'get',
    params: query
  })
}

export function queryChatList(query) {
  return request({
    url: '/visitor/queryChatList',
    method: 'get',
    params: query
  })
}


export function loginParam() {
  return request({
    url: '/visitor/wechat/loginParam',
    method: 'get'
  })
}

export function getTokenUser() {
  return request({
    url: '/visitor/getTokenUser',
    method: 'get'
  })
}

export function getTokenFn() {
  return request({
    url: '/visitor/wechat/unionidLogin?unionid=oEXoV6I3zIFt2Ic_ZV5wECnNxE0w',
    method: 'get'
  })
}

export function readOrsQuestion(){
  return request({
    url: '/visitor/readOrsQuestion',
    method: 'get'
  })
}
//咨询前保存ors量表
export function saveBookingOrs(data){
  return request({
    url: '/visitor/saveBookingOrs',
    method: 'post',
    data
  })
}

export function readSrsQuestion(){
  return request({
    url: '/visitor/readSrsQuestion',
    method: 'get'
  })
}

export function saveBookingSrs(data){
  return request({
    url: '/visitor/saveBookingSrs',
    method: 'post',
    data
  })
}

