<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

import {loginParam} from '@/api'
 export default {
   created(){
    //  this.getParam()
   },
   methods:{
     getParam(){
       loginParam().then(res=>{
         console.log(res)
         if(res.code == 200){
           let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appid}&redirect_uri=${res.data.redirect_uri}&response_type=code&scope=${res.data.scope}&state=${res.data.state}#wechat_redirect"`
           window.open(url)
         }
       })
     }
   }
 }
</script>

