<template>
  <div class="sidebar-container">
    <div class="logo-container">
      <span class="logo_text">聆心</span>
    </div>
    <div class="sidebar_wrap">
      <div>
        <ul class="menu">
          <router-link to="/index">
            <li class="menu_item" :class="{active: $route.meta.index === 0}">
              <img src="../../assets/images/wdyy.png" class="menu_icon"/>
              <span>我的预约</span>
            </li>
          </router-link>
          <router-link to="/talk">
            <li class="menu_item" :class="{active: $route.meta.index === 1}">
              <img src="../../assets/images/wdzx.png" class="menu_icon"/>
              <span>我的咨询</span>
              <!--<sup class="menu_badge">2</sup>-->
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'index',
  }
</script>

<style scoped lang="scss">
  .sidebar-container {
    width: 155px;
    background-color: #0C2C45;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
  }

  .logo-container {
    position: relative;
    width: 100%;
    height: 54px;
    line-height: 50px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    /*border-radius: 0 10px 0 0;*/
    .logo_text {
      font-size: 30px;
      color: #F59034;
    }
  }

  .sidebar_wrap {

  }

  .menu {
    border: none;
    height: 100%;
    width: 100% !important;
    .menu_item {
      width: 100%;
      color: #ffffff;
      height: 63px;
      font-size: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background: url("../../assets/images/menu_active.png");
      }
      .menu_icon {
        margin-right: 8px;
        width: 15px;
        height: 15px;
      }
      .menu_badge {
        width: 18px;
        line-height: 18px;
        background: #F45454;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        right: 12px;
      }
    }
  }
</style>
