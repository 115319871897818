import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/auth';
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    user:getToken('userInfo')?JSON.parse(getToken('userInfo')):{}
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          commit('SET_USER', user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
          resolve()
      })
    },
  },
  modules: {
  }
})
